/* You can add global styles to this file, and also import other style files */

$gray: #6c757d;
$button_color: #29ba74;
$light: rgb(235, 235, 235);
$dark: rgb(20, 20, 20);

.bg-black {
  background-color: $dark !important;
  color: $light;
}

.bg-black-nav {
  background-color: transparent !important;
  color: $button_color;
}

section {
  min-height: 30vh;
}

.img-div {
  object-fit: cover;
  height: 40vh;
  width: 100%;
  transition-property: filter;
  transition-duration: 0.75s;
  transition-timing-function: ease-in;
}

.grayscale {
  filter: grayscale(100%);
  transition-property: filter;
  transition-duration: 1.5s;
  transition-timing-function: ease-in;
}

.btn {
  color: $button_color;
}

.btn-outline-primary {
  border-color: $button_color;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  color: $light;
  border-color: $button_color;
  background-color: $button-color;
  outline: none;
  box-shadow: none;
}
